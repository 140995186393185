import React from "react";
import Stack from 'react-bootstrap/Stack';
import Link from "./Link";
import Card from 'react-bootstrap/Card';
import Container from "react-bootstrap/esm/Container";
import PropTypes from 'prop-types';

const Category = (props) => {
  console.log(props.category);

  return (
    <Stack gap={3}>
      <Card className="text-center">
        <Card.Title><h3>{props.categoryName}</h3></Card.Title>
        <Card.Body>
          {
            Object.values(props.category).map((item) => (
              //console.log(props.links[category])
              //<a href={item.URL}>{item.text}</a>
              <Link key={item.text} link={item} />
            ))}
        </Card.Body>
      </Card>
      <Container></Container>
    </Stack >
  );
};

export default Category;

Category.propTypes = {
  category: PropTypes.array,
  categoryName: PropTypes.string
};