import React from "react";
import Category from "./Category";
import GoogleSearch from "./GoogleSearch";
import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

const Contents = () => {
  var links = {
    Media: [
      { text: "Jouluradio", URL: "https://jouluradio.fi/" },
      { text: "ESS", URL: "https://ess.fi" },
      { text: "Ilta-Sanomat", URL: "https://is.fi" },
      { text: "Areena", URL: "http://areena.yle.fi/tv" },
      { text: "Ruutu", URL: "http://www.ruutu.fi/" },
      {
        text: "Tilannehuone",
        URL: "http://www.tilannehuone.fi/halytysmap.php",
      },
      { text: "TV-opas", URL: "https://areena.yle.fi/tv/opas" },
      {
        text: "Nastola-lehti",
        URL: "https://nakoislehti.media.fi/seutuneloset/archive",
      },
    ],
    Asiointi: [
      { text: "OP", URL: "http://op.fi/" },
      { text: "Omakanta", URL: "http://kanta.fi/" },
    ],
    Paikallissää: [
      { text: "Lahti", URL: "http://ilmatieteenlaitos.fi/saa/Lahti" },
      { text: "Kuhmoinen", URL: "http://ilmatieteenlaitos.fi/saa/Kuhmoinen" },
      { text: "Helsinki", URL: "http://ilmatieteenlaitos.fi/saa/Helsinki" },
    ],
    Sadetutkat: [
      { text: "IS", URL: "https://www.supersaa.fi/sadetutka/etela-suomi/" },
      {
        text: "Foreca",
        URL: "https://www.foreca.fi/Finland/Lahti/kartta/tasmatutka/Lahti",
      },
    ],
    Sääsivustot: [
      { text: "MTV3", URL: "http://mtv.fi/saa/" },
      { text: "YLE", URL: "http://yle.fi/saa/" },
      { text: "Foreca", URL: "http://foreca.fi" },
      { text: "Ilmatieteen laitos", URL: "https://www.ilmatieteenlaitos.fi/" },
    ],
    "Eläin-livet": [
      { text: "Yle Pentulive", URL: "https://areena.yle.fi/1-60895864" },
      { text: "WWF Norppalive", URL: "https://wwf.fi/luontolive/norppalive/" },
      {
        text: "WWF Saariston Norppa",
        URL: "https://wwf.fi/luontolive/itamerennorppa/",
      },
      { text: "WWF Sääksi", URL: "https://wwf.fi/luontolive/saaksi/" },
      { text: "WWF Lapinpöllö", URL: "https://wwf.fi/luontolive/lapinpollo/" },
      { text: "WWF Talvilintu", URL: "https://wwf.fi/luontolive/talvilintu/" },
    ],
  };

  return (
    <>
      <Container className="bg-light">
        <Stack className="bg-primary text-white" gap={3}>
          <Row>
            <Col></Col>
            <Col xs={6}>
              <div className="text-center">
                <h1>{process.env.REACT_APP_SITE_TITLE}</h1>
              </div>
            </Col>
            <Col>
              <Button
                className="float-end me-3 m-2"
                variant="light"
                onClick={() => window.location.reload(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-clockwise"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                  />
                  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                </svg>
              </Button>
            </Col>
          </Row>
        </Stack>
        <Stack gap={3}>
          <div className="mx-auto">
            <p></p>
          </div>
          <div className="mx-auto">
            <GoogleSearch />
          </div>
          <div className="mx-auto">
            <p></p>
          </div>
          <div className="mx-auto">
            {Object.keys(links).map((category) => (
              //console.log(category)
              <Category
                key={category}
                categoryName={category}
                category={links[category]}
              />
            ))}
          </div>
          <div className="mx-auto">
            <p></p>
          </div>
        </Stack>
      </Container>
    </>
  );
};

export default Contents;
