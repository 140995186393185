import React from "react";

import 'bootstrap/dist/css/bootstrap.min.css';

import Contents from "./components/Contents";

function App() {

  return (
    <React.Fragment>
      <Contents />
    </React.Fragment>
  );
}

export default App;