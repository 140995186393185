import React from "react";
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

const GoogleSearch = (props) => {
  //console.log(props);

  return (
    <Stack>
      <Form method="get" action="https://www.google.com/search">

        <InputGroup size="lg">
          <Form.Control
            id="searchText"
            name="q"
            placeholder="Google-haku"
          />
          <Button type="submit" variant="primary" id="button-addon1">
            Hae
          </Button>
        </InputGroup>
      </Form>
    </Stack >
  );
};

export default GoogleSearch;
