import React from "react";
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';

const Link = (props) => {
  //console.log(props);

  return (
    <>
      <Button variant="primary" className="m-2" size="lg"
        href={props.link.URL}
      >
        <b>{props.link.text}</b>
      </Button>
      {' '}
    </>
  );
};

export default Link;

Link.propTypes = {
  link: PropTypes.object,
};